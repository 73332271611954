<template>
  <div class="container px-6 mx-auto grid dark:bg-gray-900">
    <h2
      class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 text-left"
    >
      Prestamos a personal
    </h2>
    <form @submit.prevent="saveLoans" autocomplete="off">
      <div class="grid gap-6 mb-8 md:grid-cols-2 lg:grid-cols-3">
        <!-- Card -->
        <div
          class="flex items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800"
        >
          <div
            class="hidden lg:block p-3 mr-4 text-yellow-500 bg-yellow-100 rounded-full dark:text-yellow-100 dark:bg-yellow-500"
          >
            <i class="icon-user"></i>
          </div>
          <div class="w-full">
            <ComboBasic
              :items="loansEmployees"
              title="Empleado *"
              v-model="staffCode"
            />
          </div>
        </div>
        <!-- Card -->
        <div
          class="flex items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800"
        >
          <div
            class="p-3 mr-4 text-green-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-green-500"
          >
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <div>
            <p
              class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
            >
              SALARIO NETO
            </p>
            <p
              v-if="SaNeto !== null || SaNeto > 0"
              class="text-base font-semibold text-gray-700 dark:text-gray-200"
            >
              $ {{ SaNeto }}
            </p>
          </div>
        </div>
        <!-- Card -->
        <div
          class="flex items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800"
        >
          <div
            class="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-green-100 dark:bg-green-500"
          >
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <div>
            <p
              class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
            >
              CAPACIDAD DE E.M
            </p>
            <p class="text-base font-semibold text-gray-700 dark:text-gray-200">
              $ {{ cde }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="grid gap-3 grid-cols-2 mb-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"
      >
        <!-- Cards -->
        <div
          class="grid gap-3 min-w-0 px-2 py-6 lg:col-span-2 bg-white rounded-lg shadow dark:bg-gray-800 lg:grid-cols-2 xl:grid-cols-3"
        >
          <div>
            <FielText
              nameInpt="Saldo Prestamo *"
              model="saldo"
              v-model="balance"
              :defaults="balance"
              @input="calcFromBalance"
            />
          </div>
          <div>
            <FielText
              nameInpt="Cantidad Quincenal"
              model="quantity"
              v-model="quantity"
              :defaults="quantity"
              :disabled="true"
            />
          </div>
          <div>
            <!-- <div class="mb-4 relative">
							<label :id="amount + '-1'" for="input" class="block text-gray-500 text-xs text-left font-bold ml-1 -mb-8" >
								{{nameInpt}}
							</label>
							<input :id="amount" type="text" v-model="amount" @blur="activeLabel($event)" @focus="activeLabel($event)"  class="bg-gray-300 bg-opacity-25 appearance-none border-b-2 border-blue-600 w-full pt-3 mb-2 px-1 text-gray-600 leading-tight focus:border-yellow-500 focus:outline-none mt-2" >
							
						</div> -->
            <FielText
              nameInpt="Monto Por Cuota"
              model="amount"
              :v-model="amount"
              :defaults="amount"
              :disabled="true"
            />
          </div>
          <div>
            <FielText
              nameInpt="Cuotas"
              model="cuotas"
              v-model="cuotas"
              :defaults="cuotas"
              @input="calcFromCuotas"
            />
          </div>
          <div>
            <FielText
              nameInpt="Monto A Descontar Mensual"
              model="amountMensual"
              :v-model="amountMensual"
              :defaults="amountMensual"
              :disabled="true"
            />
          </div>
          <div></div>
          <div class="col-span-3">
            <label class="block mt-4 text-sm">
              <span class="text-gray-700 dark:text-gray-400">Comentario</span>
              <textarea
                v-model="commentContent"
                class="block w-full mt-1 text-sm border-2 rounded border-gray-300 dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-textarea focus:border-blue-600 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray p-1"
                rows="3"
                placeholder="Ingrese algun comentario."
              ></textarea>
            </label>
          </div>
        </div>
        <div class="min-w-0 p-4 bg-white rounded-lg shadow dark:bg-gray-800">
          <h4 class="mb-4 font-semibold text-gray-600 dark:text-gray-300">
            Prestamos anteriores
          </h4>
          <div
            v-for="(item, index) in historyLoans"
            :key="index"
            class="border-b-2 border-gray-200 text-gray-600 dark:text-gray-400 grid gap-1 mb-8 md:grid-cols-2 lg:grid-cols-2"
          >
            <div class="text-xs text-left">
              Prestamo: {{ item.initialBalance }}
            </div>
            <div class="text-xs text-left">
              Balance: {{ item.balance }}
              <button
                type="button"
                @click.prevent="
                  payLoans(item.hrpermanentTransactionId, item.balance)
                "
                class="px-1 rounded text-right bg-blue-100"
              >
                <i class="icon-money text-blue-500"></i>
              </button>
            </div>
            <div class="text-xs text-left">Cuotas R: {{ item.cuotas }}</div>
            <div class="text-xs text-left">Monto: {{ item.amount }}</div>
          </div>
        </div>
        <div class="col-span-3 inline justify-items-center">
          <div v-if="loading === false">
            <Btn title="Guardar" />
          </div>
          <div v-else>
            <button
              type="button"
              disabled
              class="cursor-not-allowed w-32 px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-purple"
            >
              <i class="con-arrows-cw animate-pulse"></i>
              Processing
            </button>
          </div>
        </div>
      </div>
    </form>

    <!-- modal -->
    <div
      v-if="modal"
      class="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50"
    >
      <div class="bg-white rounded shadow-lg w-1/3">
        <!-- modal header -->
        <div class="border-b px-4 py-2 flex justify-between items-center">
          <h3 class="font-semibold text-lg">Pagar Prestamo</h3>
          <button @click="modal = false" class="text-black close-modal">
            &cross;
          </button>
        </div>
        <!-- modal body -->
        <div class="p-3">
          <div class="text-left">
            Balance: {{ amountBalanceTotal }} <br />
            <label for="labeTotal">
              <input
                type="radio"
                name="typePay"
                id="labeTotal"
                @click="amountType = true"
                checked="checked"
              />
              Monto Total</label
            ><br />
            <label for="labelOther">
              <input
                type="radio"
                name="typePay"
                id="labelOther"
                @click="amountType = false"
              />
              Otro Monto</label
            >
          </div>
          <form autocomplete="off">
            <div class="">
              <input v-model="idPerTrans" type="hidden" />
              <div v-if="amountType === true" class="w-full">
                <label for="total" class="inline"> Monto Total: </label>
                <input
                  type="text"
                  v-model="amountBalanceTotal"
                  readonly="readonly"
                  id="total"
                  class="mt-1 text-sm border-2 dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus: shadow-none rounded dark:text-gray-300 dark:focus:shadow-outline-gray form-input bg-gray-200"
                />
              </div>
              <div v-else class="w-full inline">
                <label for="otros" class="inline">Otro Monto: </label>
                <input
                  type="text"
                  @keyup="validateBalance"
                  v-model="amountOther"
                  id="otros"
                  class="mt-1 text-sm border-2 dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus: shadow-none rounded dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                />
              </div>
              <div
                v-show="error === true"
                class="w-full bg-red-300 text-white font-bold mt-2"
              >
                El monto, no puede ser superior al balance
              </div>
            </div>
          </form>
        </div>
        <div class="flex justify-end items-center w-100 border-t p-3">
          <button
            @click="modal = false"
            class="bg-red-600 hover:bg-red-700 px-3 py-1 rounded text-white mr-1 close-modal"
          >
            Cancel
          </button>

          <button
            type="button"
            @click.prevent="processPayLoans"
            class="bg-blue-600 hover:bg-blue-700 px-3 py-1 rounded text-white"
          >
            Atualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ComboBasic from "@/components/util/ComboBoxBasic.vue";
import FielText from "@/components/util/FielText.vue";
import Btn from "@/components/util/btnBasic.vue";
import Axios from "axios";
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  name: "Loans",
  components: {
    ComboBasic,
    FielText,
    Btn,
  },
  props: {},
  data() {
    return {
      loansEmployees: null,
      staffCode: null,
      balance: null,
      loading: false,
      quantity: 1,
      amount: 0,
      amountMensual: 0,
      historyLoans: [],
      // cuotas = 24Cuotas - ((mesEnCurso-1)*2)
      // ej: si se pide el prestamo antes del 14 de enero la persona tendra 24 cuotas para pagar
      // si la pide despues del 15 de enero, tendra 22 cuotas para pagar. por ello se le resta 1 al mes en curso
      cuotas: 24 - (this.currentMonth() - 1) * 2,
      cde: 0,
      SaNeto: null,
      URL: process.env.VUE_APP_URL,
      // variables para el modal
      modal: false,
      amountType: true,
      idPerTrans: null,
      amountBalanceTotal: 0,
      amountOther: 0,
      error: false,
      commentContent: "",
    };
  },
  computed: {
    ...mapState(["user", "access_token"]),
  },
  mounted() {
    this.getEmployees();
  },

  methods: {
    async getEmployees() {
      const URL = `${this.URL}hr-employees/${this.user.countryId}/${this.user.companyId}`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token.replace(/['"]+/g, "")}`,
        },
      };

      try {
        let employees = await Axios.get(URL, fetchConfig);
        this.loansEmployees = employees.data.map((item) => {
          return { text: item.shortName, value: item.staffCode };
        });
      } catch (error) {
        Object.keys(error).forEach((key) => {
          // console.log(error[key])
        });
      }
    },
    payLoans(id, balance) {
      this.idPerTrans = id;
      this.amountBalanceTotal = balance;
      this.modal = !this.modal;
    },
    async processPayLoans() {
      const URL = `${this.URL}perm-trans-pay`;
      let balance = 0;
      if (this.amountType === true) {
        balance = parseFloat(this.amountBalanceTotal);
      } else {
        balance = parseFloat(this.amountOther);
      }
      if (balance <= 0) {
        alert("Debe agregar un monto");
        return;
      }
      const params = {
        id: this.idPerTrans,
        balance: balance,
      };
      // return
      try {
        let loans = await Axios.post(URL, params);
        // console.log(loans)
        this.historyPrestamos(this.staffCode);
        this.modal = false;
        this.amountType = true;
        this.amountOther = 0;
        this.amountBalanceTotal = 0;
      } catch (error) {
        Object.keys(error).forEach((key) => {
          console.log(error[key]);
        });
      }
    },
    currentMonth(m = 0) {
      // m = mes puede variar entre 0 y 1
      // 0 = mes Actual
      // 1 = meses restantes

      let dataTime = new Date();
      let dd = dataTime.getDate();
      let mm = dataTime.getMonth() + 1;
      let yyyy = dataTime.getFullYear();
      let month = null;

      if (m === 0) {
        if (dd >= 15) {
          // si dd="Dia en curso" es 15 o mas, se calcula fecha para proximo mes
          mm = mm + 1;
        }
        month = mm;
      } else if (m === 1) {
        if (dd >= 15) {
          mm = mm + 1;
        }
        month = 12 - mm;
      }
      return month;
    },
    calcFromBalance(event) {
      if (this.staffCode === null) {
        alert("Debe seleccionar un empleado");
        this.balance = null;
        return;
      }
      let amount = 0;
      amount = event / this.cuotas;
      amount = amount.toFixed(2);
      this.amount = amount;
      this.amountMensual = amount * 2;
      // console.log(this.amount)

      let amountS = parseFloat(this.amountMensual);
      let cdeS = parseFloat(this.cde);
      let cuotas = parseFloat(this.cuotas);
      if (cuotas > 0) {
        if (amountS > cdeS) {
          alert("Supera el limite de Capacidad de pago");
          this.balance = 0;
        }
      }
    },
    calcFromCuotas(event) {
      // console.log(event)
      let amount = 0;
      amount = this.balance / event;
      amount = amount.toFixed(2);
      this.amount = amount;
      this.amountMensual = amount * 2;
    },
    saveLoans() {
      let amountS = parseFloat(this.amountMensual);
      let cdeS = parseFloat(this.cde);
      let balance = this.balance;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token.replace(/['"]+/g, "")}`,
        },
      };

      if (balance === null || this.staffCode === null) {
        alert("Los campos con * son obligatorios");
        return;
      }
      if (balance < 1 || this.amount < 1) {
        alert("Debe ingresar un monto");
        return;
      }
      let param = {
        countryId: this.user.countryId,
        companyId: this.user.companyId,
        staffCode: this.staffCode,
        transactionTypeCode: 2004,
        quantity: this.quantity,
        amount: this.amount,
        balance: this.balance,
        initialBalance: this.balance,
        cuotas: this.cuotas,
        commentContent: this.commentContent,
        blocked: 0,
      };

      if (amountS > cdeS) {
        alert("Supera el limite de Capacidad de pago");
        return;
      }
      // console.log(param)
      // console.log(amountS)
      // console.log(cdeS)
      // return
      try {
        this.loading = true;
        const URL = `${this.URL}perm-trans`;
        Axios.post(URL, param, fetchConfig)
          .then((result) => {
            console.log(result);
            if (result.status === 200) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "success",
                title: "success",
              });
              this.historyPrestamos(this.staffCode, true);
            }
            this.amount = 0;
            this.cuotas = 6;
            this.balance = 0;
            this.loading = false;
            this.commentContent = "";
          })
          .catch((err) => {
            Object.keys(err).forEach((key) => {
              console.log(err[key]);
              this.loading = false;
            });
          });
      } catch (error) {
        Object.keys(error).forEach((key) => {
          console.log(error[key]);
        });
        this.loading = false;
      }
    },
    historyPrestamos(staffCode, calculate = false) {
      const URL = `${this.URL}hr-history-loans/${staffCode}`;
      Axios.get(URL)
        .then((result) => {
          // console.log(result)
          this.historyLoans = result.data;

          if (calculate === true) {
            let calculateCde = this.historyLoans;
            calculateCde.forEach((item) => {
              let amount = parseFloat(item.amount);
              // console.log(amount)
              let amountCde = this.cde - amount * 2;
              this.cde = amountCde.toFixed(2);
            });
          }
          // console.log(this.historyLoans)
        })
        .catch((err) => {
          Object.keys(err).forEach((key) => {
            console.log(err[key]);
          });
        });
    },
    validateBalance() {
      let amountOther = this.amountOther;
      let amountBalanceTotal = this.amountBalanceTotal;
      amountOther = parseFloat(amountOther);
      amountBalanceTotal = parseFloat(amountBalanceTotal);
      if (amountOther > amountBalanceTotal) {
        this.error = true;
        this.amountOther = 0;
      }
    },
  },

  watch: {
    staffCode: function (val) {
      // console.log('mes: ' + this.currentMonth())
      // console.log(val)
      const URL = `${this.URL}net-salary/${val}/${this.user.countryId}/${this.user.companyId}`;
      // console.log(URL2)
      Axios.get(URL)
        .then((result) => {
          // salario neto
          // console.log(result)
          this.SaNeto = result.data.neto.toFixed(2);

          // formula para la capacidad de endeudamiento mensual
          let cdeMM = 0;
          let cuotas = this.cuotas;
          let salaryMen = 0;

          //para salario mensual
          salaryMen = this.SaNeto;

          // Nuevo calculo, Salario neto * 40%
          cdeMM = salaryMen * 0.4;
          this.cde = cdeMM.toFixed(2);

          this.historyPrestamos(val, true);
        })
        .catch((err) => {
          Object.keys(err).forEach((key) => {
            console.log(err[key]);
          });
        });
    },
  },
};
</script>

<style></style>
